/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=IM+Fell+DW+Pica&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}


html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow: "hidden";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img,
picture {
  max-width: 100%;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

a:-webkit-any-link {
  text-decoration: none;
  color: inherit;
}

.active-link {
  color: "#8bc34a";
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
